<template>
  <b-modal centered
    id="delete-confirm-pop-modal"
    size="md"
    top
    hide-header
    no-close-on-backdrop="true"
  >
    <div slot="modal-header" class="row p-0 m-0 ">
      <img
        class="delete-indicator m-auto"
        @click="closeModal()"
      />
    </div>
    <div class="text-center mt-3">
      <p class="confirm-message">Do you want to delete "{{msg}}" ?</p>
      <div class="my-3">
        <b-button
          class="delete-no"
          :label="NO"
          :buttonStyle="confirmCancel"
          @click="handleDelete(false)"
          defaultValue="xyz"
        >NO</b-button>
        <b-button
          class="ml-5 delete-yes"
          :label="YES"
          :buttonStyle="confirmDelete"
          @click="handleDelete(true)"
        >YES</b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>


export default {
  props: ["msg"],
  components: {
   
  },
  methods: {
    handleDelete(isDelete) {
        this.$emit("deleteData", isDelete);
    }
  }
};
</script>
<style scoped>
.confirm-message {
  font-size: 16px;
  color: #090909;
  font-weight: 600;
}
.delete-indicator {
  width: 50px;
}
.delete-no{
  background: #1592E6;
  color: #fff;
  font-size: 14px;
  width: 85px;
}
.delete-yes{
  color: #1592E6;
  background: #fff;
  border: 1px solid #1592E6;
  font-size: 14px;
  width: 85px;
}
</style>
<style>
.modal-footer {
  display: none !important;
}

</style>