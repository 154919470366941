<template>
  <div class="view-channel-card">
    <div class="ml-3" style="border-bottom:1px solid #eee6e6">
      <p class="channel-name pt-2 mb-0">
        <img style="margin-top:3px; margin-right:5px" :src="marketPlaceLogo" :alt="companyname" width="30" height="20" />
        {{ channelName }}
      </p>
      <p class="subChannel-name pt-3 pb-2 mb-0">{{ channelName }}</p>
    </div>
    <p class="label-name ml-3">
      Created : <span class="pl-3" style="color:#767676">{{ createdOn }}</span>
    </p>
    <div class="label-name ml-3 d-flex align-items-center">
      Sync :
      <div class="row p-0 m-0 d-flex justify-content-between">
        <div class="col-lg-3 col-md-6 col-12 text-center p-0 m-0">
          <span style="color:#767676">Order <span
            class="green-circle"
            v-if="syncname.orderSyncEnabled === true"
          ></span>
          <span class="red-circle" v-else></span></span>
          
        </div>

        <div class="col-lg-4 col-md-6 col-12 text-center p-0 m-0">
          <span style="color:#767676">Inventory <span
            class="green-circle"
            v-if="syncname.inventorySyncEnabled === true"
          ></span>
          <span class="red-circle" v-else></span></span>
          
        </div>

        <div class="col-lg-5 col-md-6 col-12 text-center p-0 m-0">
          <span style="color:#767676">Remittance <span
            class="green-circle"
            v-if="syncname.priceSyncEnabled === true"
          ></span>
          <span class="red-circle" v-else></span></span>
          
        </div>
      </div>
    </div>
    <p class="label-name ml-3">
      Status :
      <span class="pl-3" style="color:#767676;">{{ channelStatus }}</span>
    </p>
    <div class="row button-section">
      <button @click="editChannel()" class="edit-channel" v-if="isPermission('allow_edit_channel_config')"
        >Edit</button
      >
      <button
        @click="deleteChannel()"
        class="delete-channel"
        v-if="isPermission('allow_delete_channel')"
        >Delete</button
      >
    </div>
  </div>
</template>

<script>
import { isAuthorized } from "../Service/CommonService";
export default {
  data() {
    return {};
  },
  props: ["channelName", "className", "createdOn", "syncname", "channelStatus", "channelID", "marketID", "marketPlaceLogo"],

  methods: {
    // Permission based validation
    isPermission(key){
      return isAuthorized(key)
    },
    
    //  getImgUrl() {
    //   var images = require.context("../assets/", false, /\.svg$/);
    //   return images("./" + this.picname + ".svg");
    // },
    deleteChannel() {
      let data = {
        channelID: this.channelID,
        channelName: this.channelName,
        createdOn: this.createdOn,
        syncname: this.syncname,
        channelStatus: this.channelStatus,
        marketID:this.marketID
      };
      this.$emit("deleteChannel", data);
    },
    editChannel() {
      let data = {
        channelID: this.channelID,
        channelName: this.channelName,
        createdOn: this.createdOn,
        syncname: this.syncname,
        channelStatus: this.channelStatus,
        marketID:this.marketID
      };
      this.$emit("editChannel", data);
    },
  },
};
</script>

<style scoped>
.view-channel-card {
  /* width: 22.6%; */
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  border: solid 1px #eee6e6;
  background-color: #ffffff;
  /* padding-right: 0.2rem; */
  /* padding: .5rem; */
}
.channel-name {
  font-family: "proxima-nova", sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  color: #090909;
}
.subChannel-name {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 13px;
  line-height: 1.2;
  color: #363535;
}
.view-channel-card .label-name {
  font-size: 12px;
  color: #363535;
  font-family: "proxima-nova", sans-serif;
  border-bottom: 1px solid #eee6e6;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 0;
}
.button-section {
  display: flex;
  justify-content: space-evenly;
  margin: 13px;
}
.edit-channel {
  border-radius: 2px;
  background-color: #2e8cd3;
  color: #fff;
  padding: 3px 20px;
  font-size: 13px;
  font-family: "proxima-nova", sans-serif !important;
}
.delete-channel {
  border-radius: 2px;
  border: solid 1px #2e8cd3;
  color: #2e8cd3;
  padding: 3px 15px;
  font-size: 13px;
  font-family: "proxima-nova", sans-serif !important;
}
.green-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #0ff25b;
  display: inline-block;
  margin-left: 2px;
}
.red-circle {
  width: 10px;
  height: 10px;
  border-radius: 70%;
  background-color: #f2360f;
  display: inline-block;
  margin-left: 2px;
}
@media screen and (max-width: 768px) {
  .view-channel-card {
    width: 100%;
  }
  .edit-channel {
    width: 45%;
  }
  .delete-channel {
    width: 45%;
  }
}
</style>
