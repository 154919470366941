import { Apihelper } from "./API/apiHelper.js";
const apiHelper = new Apihelper();
const baseUrl = process.env.VUE_APP_CHANNEL_SERVER_URL;
const baseUrlforWarehouseList = process.env.VUE_APP_WAREHOUSE_SERVER_URL


// get channel status
export const getChannelsStatus = () => {
    const uri = `${baseUrl}/api/v1/companies/channels/channel-statuses`;
    return apiHelper.get(uri);
}

// get warehouse list detail
export const getChannelWarehouse = (companyId, marketPlaceId) => {
    const uri = `${baseUrlforWarehouseList}/api/v1/warehouses/for-channel-linking?marketplace-id=${marketPlaceId}`;
    return apiHelper.get(uri);
}

// get channel type of marketplace
export const getChannelType = () => {
    const uri = `${baseUrl}/api/v1/get/marketplaces`;
    return apiHelper.get(uri);
}
export const getImageUrlByID = (id) => {
    const uri = `${baseUrl}/api/v1/marketplaces/${id}`;
    return apiHelper.get(uri);
}

// get channel details with params  

export const getChannelsWithParams = (name, status, id, companyId) => {
    let uri = `${baseUrl}/api/v1/companies/channels/filters?page-no=0&page-size=100`

    if (name) {
        uri = uri.concat('&channel-name=', name);
    }
    if (status) {
        uri = uri.concat('&channel-status=', status);
    }
    if (id) {
        uri = uri.concat('&marketplace-id=', id);
    }

    return apiHelper.get(uri);
}


// get channel detailsssss
export const getChannelDetails = (companyId) => {
    const uri = `${baseUrl}/api/v1/companies/channels-details`
    return apiHelper.get(uri);
}

// get channnel detail by ID for Edit      
export const getChannelProfileById = (channelID, companyId) => {
    const uri = `${baseUrl}/api/v1/channels/${channelID}`;
    // const uri = `${baseUrl}/api/v1/channels/hashed-data/` + channelID;
    return apiHelper.get(uri);
}

// add new channel
export const addNewChannel = (data, marketplace, companyId) => {
    const uri = `${baseUrl}/api/v1/companies/channel/${marketplace}`;
    return apiHelper.post(uri, data);
}

// for update channel
export const updateChannelDetails = (data, marketplace, companyId) => {
    const uri = marketplace === 'amazon' ? `${baseUrl}/api/v1/companies/update-channels` : `${baseUrl}/api/v1/companies/channel/${marketplace}`;
    return apiHelper.put(uri, data);
}

// for delete channel
export const deleteChannelDetails = (id) => {
    const uri = `${baseUrl}/api/v1/companies/delete-channels/${id}`;
    return apiHelper.remove(uri);
}