<template>
  <div class="has-search">
    <span class="form-control-feedback">
      <img src="@/assets/Search.svg" style="width: 24px;
  height: 24px;" @click="clickedSearch" />
    </span>
    <input
      type="text" placeholder="search"
      class="form-control"
      v-model="searchText"
      @keypress.enter="clickedSearch"
    />
  </div>
</template>
<script>
export default {
  props: ["placeholder"],
  data() {
    return {
      searchText: ""
    };
  },
  methods: {
    clickedSearch() {
      this.$emit("searchedText", this.searchText);
    }
  }
};
</script>
<style scoped>
.has-search .form-control {
  padding-left: 2.375rem;
}
.has-search .form-control-feedback {
 position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    bottom: -9px;
    right: 22rem;

}
.search-bar .input-group-prepend .input-group-text {
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #9699a0;
  border-right: 0px;
  background: transparent;
  cursor: pointer;
  height: 100%;
}

.search-bar .form-control {
  height: 100%;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #9699a0;
  font-size: 22px;
}

.search-bar .form-control:focus {
  box-shadow: none;
}
.search-bar .form-control::placeholder {
  font-size: 22px;
  font-style: cabin;
  color: #9699a0;
}
</style>