<template>
  <div class="channel-warehouse">
    <div class="view-channel">

      <!-- <span class="ml-2 summary_refresh_text"
        >Last Updated On: {{ lastUpdated ? lastUpdated : "-" }}</span
      >&nbsp;&nbsp;<span class="summary_refresh">
        <img
          style="cursor:pointer"
          @click="loadData()"
          src="../../assets/refresh.png"
          width="15px"
        />
      </span> -->
      <!-- <b-card class="m-2" style="margin-top: 0 !important">
        <div class="row ml-1">
          <div class="col-md-3 p-0">
            <label class="mb-1 p-0 input-label">Channel Name</label>
            <w-text-input
              :fieldType="'text'"
              :disabled="false"
              :placeholder="'Enter channel name'"
              :defaultValue="enterChannelName"
              :labelStyle="'register-label'"
              @textInputChange="onEnterchannelName($event)"
            />
          </div>

          <div class="col-md-3 p-0 ml-4">
            <label class="mb-1 p-0 input-label">Status</label>
            <w-dropdown
              :label-text="''"
              :selectedValue="selectedStatus"
              :selectOption="statusList"
              @selectedOption="onChangeSelectStatus($event)"
            />
          </div>

          <div class="col-md-3 p-0 ml-4">
            <label class="mb-1 p-0 input-label">Marketplace</label>
            <w-dropdown
              :label-text="''"
              :selectedValue="selecteMarketplaceType"
              :selectOption="marketList"
              @selectedOption="onChangeSelectMarketPlace($event)"
            />
          </div>
        </div>
      </b-card> -->
      <div>
          <div class="dashboard-filter mt-4">
            <b-card class="my-2 p-2 desktop-filter" v-if="isDesktopFilterOpen">
              <img
                class="mt-3 close-filter"
                src="../../assets/CloseModel.svg"
                width="15px"
                style="cursor:pointer"
                @click="closeDesktopFilter"
              />
              <div class="row">
                <div class="col-md-3 p-0">
                  <label class="mb-1 p-0 input-label">Channel Name</label>
                  <w-text-input
                    :fieldType="'text'"
                    :disabled="false"
                    :placeholder="'Enter channel name'"
                    :defaultValue="enterChannelName"
                    :labelStyle="'register-label'"
                    @textInputChange="onEnterchannelName($event)"
                  />
                </div>

                <div class="col-md-3 p-0 ml-4">
                  <label class="mb-1 p-0 input-label">Status</label>
                  <w-dropdown
                    :label-text="''"
                    :selectedValue="selectedStatus"
                    :selectOption="statusList"
                    @selectedOption="onChangeSelectStatus($event)"
                  />
                </div>

                <div class="col-md-3 p-0 ml-4">
                  <label class="mb-1 p-0 input-label">Marketplace</label>
                  <w-dropdown
                    :label-text="''"
                    :selectedValue="selecteMarketplaceType"
                    :selectOption="marketList"
                    @selectedOption="onChangeSelectMarketPlace($event)"
                  />
                </div>
                <div class="col-md-2 p-0 mt-4 text-right">
                  <!-- <w-button-input
                    :buttonClass="'auth-button'"
                    :buttonStyle="'auth-button-style'"
                    @buttonClicked="loginButton()"
                    :disabled="isloginDetail"
                    :label="'Filter'"
                  /> -->
                  <!-- <button class="clear_filter ml-4">Clear Filter</button> -->
                </div>
              </div>
              <!-- <img
              class="mt-3 close-filter"
              src="../../assets/CloseModel.svg"
              width="15px"
              style="cursor: pointer"
              @click="closeDesktopFilter"
            /> -->
            </b-card>
          </div>
        </div>
      <div class="channel_filter">
        <div class="row justify-content-between">
          <div class="my-4 py-0 px-4" @click="openDesktop_filter">
            <img
              src="../../assets/SVG/desktopFilter.svg"
              style="cursor: pointer"
            />
            <span style="color: #2e8cd3; font-size: 14px; cursor: pointer"
              >Filters</span
            >
          </div>
          <div class="my-4 py-0 px-5" v-if="isPermission('allow_add_new_channel')">
            <img
              src="../../assets/add.png"
              style="cursor: pointer"
              class="mr-1"
            />
            <span
              @click="gotToChannelType()"
              style="color: #2e8cd3; font-size: 14px; cursor: pointer"
              >Add New Channel</span
            >
          </div>
        </div>
        <!-- filter for mobile -->
        <div class="open-mobile-filter" v-if="isMobile_filter_open">
          <div class="row mx-2">
            <div class="col-2 pt-4">
              <span @click="closeApplyFilter">
                <img src="../../assets/SVG/arrow-back.svg" />
              </span>
            </div>
            <div
              class="col-8 pt-0 d-flex justify-content-center"
              style="
                border-bottom-left-radius: 50%;
                border-bottom-right-radius: 50%;
              "
            >
              <span
                class="text-white bg-dark py-3 px-4"
                style="
                  font-size: 20px;
                  border-bottom-left-radius: 50%;
                  border-bottom-right-radius: 50%;
                "
                >LOGO</span
              >
            </div>
            <div class="col-2"></div>
          </div>

          <b-card class="mt-5">
            <div class="row">
              <div class="col-md-3 p-0">
                <label class="mb-1 p-0 input-label">Channel Name</label>
                <w-text-input
                  :fieldType="'text'"
                  :disabled="false"
                  :placeholder="'Enter channel name'"
                  :defaultValue="enterChannelName"
                  :labelStyle="'register-label'"
                  @textInputChange="onEnterchannelName($event)"
                />
              </div>

              <div class="col-md-3 p-0 ml-4">
                <label class="mb-1 p-0 input-label">Status</label>
                <w-dropdown
                  :label-text="''"
                  :selectedValue="selectedStatus"
                  :selectOption="statusList"
                  @selectedOption="onChangeSelectStatus($event)"
                />
              </div>

              <div class="col-md-3 p-0 ml-4">
                <label class="mb-1 p-0 input-label">Marketplace</label>
                <w-dropdown
                  :label-text="''"
                  :selectedValue="selecteMarketplaceType"
                  :selectOption="marketList"
                  @selectedOption="onChangeSelectMarketPlace($event)"
                />
              </div>
              <div class="col-md-3 mt-3 text-right">
                <!-- <w-button-input
                  :buttonClass="'auth-button'"
                  :buttonStyle="'auth-button-style'"
                  @buttonClicked="loginButton()"
                  :disabled="isloginDetail"
                  :label="'Filter'"
                /> -->
                <!-- <button class="clear_filter ml-4">Clear Filter</button> -->
              </div>
            </div>
          </b-card>
        </div>
        <!-- mobile filter end -->

        
      </div>

      <!-- <b-card class="m-2">
        <div class="row d-flex justify-content-between">
          <span class="col p-2 ml-1" style="color: #090909; font-weight: bold">
            VIEW CHANNELS
          </span>
          <span class="col text-right">
            <button
              type="submit"
              class="add-newChannel mr-1"
              @click="gotToChannelType()"
            >
              Add New Channel
            </button>
          </span>
        </div> -->

      <div class="row m-1 channel-card-wrapper">
        <!-- for loader -->
        <div class="loader-div d-flex" v-if="isloader">
          <div class="loader"></div>
          <span class="text-danger my-auto ml-2" style="font-size: 13px">
            <strong> Loading... </strong>
          </span>
        </div>
        <div class='col-lg-3 col-md-4 col-12' v-else v-for="(item,i) in carditems" :key="i">
          <w-card
            :channelName="item.channelName"
            :createdOn="item.createdOn"
            :syncname="item.sync"
            :channelStatus="item.channelStatus"
            @editChannel="editChannel($event)"
            @deleteChannel="openConfirmationModal($event)"
            :channelID="item.channelId"
            :marketID="item.marketID"
            :marketPlaceLogo="item.marketPlaceLogo"
          />
        </div>
        
      </div>
      <!-- </b-card> -->

      <w-delete-confirm-modal
        :msg="selectedChannelName"
        @deleteData="deleteChannel($event)"
      ></w-delete-confirm-modal>

      <!-- modal for error handling -->
      <Modal
        :trigger="alert"
        :title="'HectoCommerce'"
        :centered="true"
        :showModalTable="false"
        :dontCloseWhenClickedOutside="true"
        :dontCloseWhenEscapePressed="true"
        :dontShowCloseIcon="true"
        :modalbuttonStyle="true"
        :alert="alert"
        :alertMsg="alertMsg"
        :alertType="alertType"
        :isConfirmAlert="isConfirmAlert"
        @eventClose="closeModal()"
        @confirmOkEvent="confirmOk"
      />
    </div>
  </div>
</template>

<script>
import TextInput from "../../widgets/TextInput.vue";
import ChannelCard from "../../widgets/ChannelCard.vue";
import SearchBar from "../../widgets/SearchBar";
import Dropdown from "../../widgets/Dropdown.vue";
import Modal from "../../widgets/ModalWidget";
import DatePicker from "vue2-datepicker";
import { EventBus } from "@/main.js";
import DeleteConfirmModal from "../../widgets/DeleteConfirmModal.vue";
import moment from "moment";

import {
  getChannelsStatus,
  getChannelType,
  getChannelsWithParams,
  getChannelDetails,
  deleteChannelDetails,
} from "../../Service/ChannelServiceApi";
import { isAuthorized } from "../../Service/CommonService";

// import "vue2-datepicker/index.css";
export default {
  components: {
    "w-text-input": TextInput,
    "w-dropdown": Dropdown,
    "w-card": ChannelCard,
    "w-delete-confirm-modal": DeleteConfirmModal,
    SearchBar,
    Modal,
    DatePicker,
  },
  data() {
    return {
      isDesktopFilterOpen: false,
      isMobile_filter_open: false,
      companyID: sessionStorage.getItem("companyIds"),
      // for loader
      isloader: false,
      // for error handling message
      alertType: "",
      alert: false,
      alertMsg: "",
      isConfirmAlert: false,

      enterChannelName: "",
      filterByName: "",

      selectStatus: "",
      selectMarketplaceType: "",
      selectedStatus: "",
      selecteMarketplaceType: "",
      selectedChannelId: "",
      selectedChannelName: "",

      marketList: [],
      statusList: [],

      carditems: [],
      // selectDate: null,
      lastUpdated: "",
    };
  },
  created() {
    this.getChannelStatusList();
    this.getMarketListDropdown();
    // this.getChannelCardDetails();
    // this.getChannelDataWithParams();
    this.loadData();
  },
  methods: {
    // Permission based validation
    isPermission(key){
      return isAuthorized(key)
    },

    loadData() {
      this.getLastUpdated();
      this.getChannelCardDetails();
    },
    // To get  last updated time
    getLastUpdated() {
      this.lastUpdated = moment().format("MM/DD/YYYY hh:mm:ss a");
    },
    gotToChannelType() {
      // this.$router.push("/ChannelType");
      // this.$emit("goToChannelType");
      this.$router.push({ path: "/ChannelType" });
    },
    getChannelStatusList() {
      this.statusList = [];
      var status = [];
      this.statusList.push({
        value: null,
        text: "All",
      });
      getChannelsStatus()
        .then((res) => {
          status = res;
          status.map((element) => {
            this.statusList.push({
              value: element,
              text: element,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    getMarketListDropdown() {
      this.marketList = [];
      this.marketList.push({
        value: null,
        text: "All",
      });
      getChannelType()
        .then((res) => {
          res.map((element) => {
            this.marketList.push({
              value: element.marketplaceId,
              text: element.marketplaceName,
            });
          });
        })
        .catch((err) => {
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },

    // view channel detail by filter ( channel name, status and marketplace )
    getChannelDataWithParams() {
      this.carditems = [];
      // this.selectStatus = "ACTIVE,INACTIVE";
      // this.selectStatus = data.id === "status" ? data.value : this.selectStatus;
      // this.selectStatus == "All" ? this.selectStatus = "ACTIVE,INACTIVE": this.selectStatus;
      // this.filterByName = data.id === "name" ? data.value : this.filterByName;
      // this.names = data.id === "name" ? data.value : this.names;
      // this.selectStatus != "ACTIVE,INACTIVE"
      //   ? this.selectStatus
      //   : (this.selectStatus = "ACTIVE,INACTIVE");
      // this.selectMarketplaceType = data.id === "id" ? data.value : this.selectMarketplaceType;
      this.selectMarketplaceType != "All"
        ? getChannelsWithParams(
            this.enterChannelName,
            this.selectedStatus,
            this.selecteMarketplaceType,
            this.companyID
          )
            .then((res) => {
              let resp = res.response.content;
              this.carditems = [];
              resp.map((ele) => {
                this.carditems.push({
                  channelId: ele.channelId,
                  channelName: ele.channelName,
                  marketID: ele.marketplaceId,
                  marketPlaceLogo: ele.marketplaceImageUrl,
                  createdOn: moment(ele.createdOn,'YYYY-MM-DD').format('DD/MM/YYYY'), //.slice(0, ele.createdOn.indexOf(" ")),
                  sync: {
                    orderSyncEnabled: ele.orderSyncEnabled,
                    inventorySyncEnabled: ele.inventorySyncEnabled,
                    priceSyncEnabled: ele.remittanceSyncEnabled,
                    marketplaceFulfilledEnabled:
                      ele.marketplaceFulfilledEnabled,
                  },
                  channelStatus: ele.channelStatus,
                });
              });
            })
            .catch((err) => {
              this.alert = true;
              this.alertMsg = err.message;
              this.alertType = "Failure";
              this.isConfirmAlert = false;
            })
        : this.getChannelCardDetails();
    },

    // filter by channel name
    onEnterchannelName(event) {
      this.enterChannelName = event;
      this.getChannelDataWithParams();
    },

    // filter by status type
    onChangeSelectStatus(event) {
      this.selectedStatus = event;
      this.getChannelDataWithParams();
    },

    // filter by marketPlace
    onChangeSelectMarketPlace(event) {
      this.selecteMarketplaceType = event;
      this.getChannelDataWithParams();
    },

    editChannel(event) {
      // let data = JSON.stringify(event);
      sessionStorage.setItem("editDetail", JSON.stringify(event));
      // EventBus.$emit("updateChannel", event.channelID);
      // this.$router.push({ path: "/Channel/" + event.channelID}, {params: event});
      // this.$router.push({ name: 'Channel', params: {channelData: event }})
      this.$router.push({ path: "/channel/edit" });
    },
    getChannelCardDetails() {
      this.isloader = true;
      this.carditems = [];
      getChannelDetails(this.companyID)
        .then((res) => {
          this.isloader = false;
          res.map((ele) => {
            this.carditems.push({
              channelId: ele.channel.channelId,
              channelName: ele.channel.channelName,
              marketID: ele.channel.marketplaceId,
              marketPlaceLogo: ele.marketplaceImageUrl,
              createdOn: moment(ele.channel.createdOn,'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY'),
              sync: {
                orderSyncEnabled: ele.channel.orderSyncEnabled,
                inventorySyncEnabled: ele.channel.inventorySyncEnabled,
                priceSyncEnabled: ele.channel.remittanceSyncEnabled,
                marketplaceFulfilledEnabled:
                  ele.channel.marketplaceFulfilledEnabled,
              },
              channelStatus: ele.channel.channelStatus,
            });
          });
        })
        .catch((err) => {
          this.isloader = false;
          this.alert = true;
          this.alertMsg = err.message;
          this.alertType = "Failure";
          this.isConfirmAlert = false;
        });
    },
    openConfirmationModal(data) {
      this.$bvModal.show("delete-confirm-pop-modal");
      this.selectedChannelId = data.channelID;
      this.selectedChannelName = data.channelName;
    },
    deleteChannel(isDelete) {
      if (isDelete) {
        deleteChannelDetails(this.selectedChannelId)
          .then((res) => {
            this.getChannelCardDetails();
          })
          .catch((err) => {
            this.alert = true;
            this.alertMsg = err.message;
            this.alertType = "Failure";
            this.isConfirmAlert = false;
          });
      }
      this.$bvModal.hide("delete-confirm-pop-modal");
    },
    // reset selected channel filter
    resetFilter() {
      this.enterChannelName = "";
      this.selectedStatus = "";
      this.selecteMarketplaceType = "";
      // this.selectDate = "";

      // for fetch all channel details
      this.getChannelCardDetails();
    },

    // close confirmation modal
    closeModal() {
      this.alert = false;
      this.isConfirmAlert = false;
      this.alertMsg = "";
      this.alertType = "";
    },

    // for open a desktop filter
    openDesktop_filter() {
      this.isDesktopFilterOpen = !this.isDesktopFilterOpen;
    },
    closeDesktopFilter() {
      this.isDesktopFilterOpen = false;
    },
  },
};
</script>

<style scoped>
.view-channel {
  margin: 0rem 20px 0 60px;
}
.channel-warehouse ::v-deep .form-control {
  height: 35px !important;
}
.channel-warehouse ::v-deep .card-body {
  padding-top: 10px;
}

.input-label,
.date-picker-label {
  color: #767676;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: 500;
}

.add-newChannel {
  background-color: white;
  border: 1px solid #2e8cd3;
  padding: 6px;
  font-family: "proxima-nova", sans-serif !important;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  text-align: left;
  color: #2e8cd3;
  height: 30px;
  border-radius: 2px;
}
::v-deep .input-group .form-control {
  font-size: 14px;
  color: #767676;
}
::v-deep .input-group .form-control::placeholder {
  font-size: 14px;
}

.clear_filter {
  font: normal normal normal 14px/17px Roboto;
  color: #2185d0;
}

/* for loader */
.loader-div {
  margin-left: 50%;
}
.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #d9534f;
  border-left: 3px solid #d9534f;
  border-right: 3px solid #d9534f;
  width: 32px;
  height: 32px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 768px) and (max-width: 800px) {
  .view-channel[data-v-15c30922] {
    margin: 0rem;
    margin-top: -50px !important;
  }
}
@media screen and (max-width: 768px) {
  .view-channel[data-v-15c30922] {
    margin: 0rem 10px;
    margin-top: -50px !important;
  }
  .card-body .col-md-3 {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 1100px) {
  .row > .col-lg-3{
    max-width: 50% !important;
    flex: 0 0 50%;
  }
}
@media screen and (max-width: 500px) {
  .row > .col-lg-3{
    max-width: 100% !important;
    flex: 0 0 100%;
  }
}
.close-filter {
  position: absolute;
  top: 0px;
  right: 20px;
}
</style>
