<template>
  <div :class="className">
    <label class="lable-name mb-2" :class="getLabelClass()">
      {{ labelName }} <img v-if="labelTooltip" class="info-icon ml-1 mr-auto" src="../assets/info-icon.png" v-b-popover.hover.top.html="labelTooltip">
      <!-- <i v-if="togglePassword" @click="changeFeildType">
        <button>
          <i :class="btnText"></i>
        </button>
         <button>
            <i :class="btnTextRe"></i>
          </button>
      </i> -->
    </label>
    <b-input-group>
      <b-form-input
        :name="fieldName"
        :id="labelName"
        :step="step"
        :value="defaultValue"
        v-validate="validation"
        :placeholder="placeholder"
        :state="errors && errors.has(fieldName) ? false : null"
        @keyup="inputText($event)"
        :style="getStyle()"
        class="text-input m-0"
        :class="newInputStyle"
        :disabled="disabled"
        :type="fieldType"
        @keypress="inputText($event)"
        :formatter="formatter"
      ></b-form-input>
    </b-input-group>
    <div
      :class="'helo-block alert alert-danger errorWidthSmall mt-1'"
      v-show="errors.has(fieldName)"
    >
      {{ errors.first(fieldName) }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [
    "togglePassword",
    "labelName",
    "validation",
    "className",
    "disabled",
    "defaultValue",
    "fieldName",
    "labelStyle",
    "placeholder",
    "fieldType",
    "step",
    "btnText",
    "btnTextRe",
    "formatter",
    "newInputStyle",
    "labelTooltip"
  ],
  data() {
    return {};
  },
  methods: {
    inputText(evt) {
      if (this.fieldType == "number") {
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          this.$emit("textInputChange", evt.target.value);
          return true;
        }
        this.$forceUpdate();
      } else {
        this.$emit("textInputChange", evt.target.value);
      }
    },
    getStyle() {
      switch (this.newInputStyle) {
        case "media-input":
          return "border: solid 1px #c4c4c4; background-color: #ffffff;";
        case "listing-status":
          return "border: solid 2px black";
        case "role-page-input":
          return "height: 35px !important; margin: 0 !important; box-shadow:0px 1px 10px #40404029;"
        case "profile-input":
          return "border: 1px solid #D3D3D3 !important; height: 35px !important;"
        case "h-37":
          return "height: 37px !important; min-width: 250px"
        default:
          return;
      }
    },
    getLabelClass() {
      this.validation && typeof this.validation == "string"
        ? this.validation.includes("required")
          ? (this.labelStyle = "required")
          : this.labelStyle
        : null;
      switch (this.labelStyle) {
        case "login-page":
          return "label-class";
        case "settings-page":
          return "settings-class";
        case "register-label":
          return "register-page";
        case "required":
          return "label-class mandatory";
        case "login-input-label":
          return "login-input-label";
        case "role-page-label":
          return "role-page-label";
        default:
          return;
      }
    },
    changeFeildType() {
      if (this.fieldType === "password") {
        this.fieldType = "text";
      } else {
        this.fieldType = "password";
      }
    },
  },
};
</script>
<style scoped>
.info-icon {
  height:15px;
  margin-bottom:1px;
  cursor: default;
}
.errorWidth {
  font-size: 12px !important;
  width: 100% !important;
  padding: 2px !important;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}
.bold {
  font-weight: bold !important;
}
.text-input::placeholder {
  color: #707070;
  font-size: 12px;
  font-family: ProximaNova;
}
.text-input:disabled{
  opacity: .8;
}
.input-container:focus {
  outline: none;
}
.form-group > div:focus {
  outline: none !important;
}
.lable-name {
  outline: none;
  font-size: 13px;
  color: #767676;
  font-family: "proxima-nova", sans-serif !important;;
  /* font-weight: 600; */
}
.settings-class {
  font-family: "proxima-nova", sans-serif !important;
  font-weight: bold;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
}
.register-page {
  float: left;
}
label.mandatory:not(:empty):after,
.field-header.mandatory:after {
  content: " *";
  color: red;
}
.label-class {
  opacity: 10;
}


/* New Input style */
.loginInput{
  background-color:#FFFFFF; 
  box-shadow: 0px 3px 6px #5A5A5A1A !important; 
  border: 1px solid #EDEDED !important; 
  border-radius: 10px !important;
}
.login-input-label{
  color:#464646;
  font-family: "proxima-nova", sans-serif;
  font-size: 16px;
  text-align: left;
  margin-bottom: 20px !important;
  font-weight: bold;
  letter-spacing: 0px;
}
.role-page-label{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #363636;
}
@media screen and (min-width: 1000px) and (max-width: 1190px){
    
}
@media screen and (min-width: 760px) and (max-width: 1000px){
    
}
@media screen and (min-width: 320px) and (max-width: 760px){
  .loginInput{
    height: 30px !important;
    border-radius: 5px !important;
  }
  .login-input-label{
    margin-bottom: 10px !important;
  }
}
</style>
